import React, { useState } from 'react';
import { RecordContextProvider, ShowButton, EditButton, ChipField, SingleFieldList, ReferenceArrayField, useListContext } from 'react-admin';
import { Grid, Card, CardHeader, CardContent, IconButton as MUIIconButton, Menu, MenuItem } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';

const menuItemSx = {
    padding: 0,
    '& a': {
        width: '100%',
        minHeight: '36px',
        justifyContent: 'flex-start',
        padding: '4px 10px'
    }
};

const ActionButtons = () => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleOpen = (e) => {
        setAnchorEl(e.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <MUIIconButton onClick={handleOpen}>
                <MoreVertIcon />
            </MUIIconButton>
            <Menu open={open} anchorEl={anchorEl} onClose={handleClose}>
                <MenuItem sx={menuItemSx}><ShowButton /></MenuItem>
                <MenuItem sx={menuItemSx}><EditButton /></MenuItem>
            </Menu>
        </>
    );
};

const ProjectGrid = () => {
    const { data } = useListContext();

    return (
        <Grid container spacing={2} sx={{ mt: 0 }}>
            {data.map(record => (
                <RecordContextProvider key={record.id} value={record}>
                    <Grid key={record.id} item xs={12} sm={6} md={4} lg={3} xl={2}>
                        <Card>
                            <CardHeader title={record.name} subheader={record?.customer?.name} action={<ActionButtons />} />
                            <CardContent>
                                <ReferenceArrayField source="user_ids" reference="users">
                                    <SingleFieldList>
                                        <ChipField source="fullname" variant="outlined" size="small" />
                                    </SingleFieldList>
                                </ReferenceArrayField>
                            </CardContent>
                        </Card>
                    </Grid>
                </RecordContextProvider>
            ))}
        </Grid>
    );
};

export default ProjectGrid;