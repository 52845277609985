import React, { useState, useEffect } from 'react';
import { Title, Link, useTranslate, usePermissions } from 'react-admin';
import Grid from '@mui/material/Grid';
import DescriptionIcon from '@mui/icons-material/Description';
import UserIcon from '@mui/icons-material/People';
import EditCalendarIcon from '@mui/icons-material/EditCalendar';

import IconCard from './common/IconCard';

import customers from './customers';
import projects from './projects';

import { fetchAdminDashboardStats } from '../services/api';


const Dashboard = () => {
    const [stats, setStats] = useState();
    const { permissions } = usePermissions();
    const translate = useTranslate();

    useEffect(() => {
        const fetchStats = async () => {
            const data = await fetchAdminDashboardStats();
            setStats(data);
        }

        fetchStats();
    }, []);

    return (
        <>
            <Title title="Kernel" />
            <Grid container spacing={2} sx={{ marginTop: '15px' }}>
                <Grid item lg={3} sm={6} xl={3} xs={12}>
                    <Link to="/invoices">
                        <IconCard
                            title={translate('resources.invoices.name', { smart_count: 2, _: "Invoices" })}
                            value={stats?.invoices}
                            icon={<DescriptionIcon />}
                        />
                    </Link>
                </Grid>
                <Grid item lg={3} sm={6} xl={3} xs={12}>
                    <Link to="/customers">
                        <IconCard
                            title={translate('resources.customers.name', { smart_count: 2, _: "Customers" })}
                            value={stats?.customers}
                            icon={<customers.icon />}
                        />
                    </Link>
                </Grid>
                <Grid item lg={3} sm={6} xl={3} xs={12}>
                    <Link to="/projects">
                        <IconCard
                            title={translate('resources.projects.name', { smart_count: 2, _: "Projects" })}
                            value={stats?.projects}
                            icon={<projects.icon />}
                        />
                    </Link>
                </Grid>
                <Grid item lg={3} sm={6} xl={3} xs={12}>
                    <Link to="/users">
                        <IconCard
                            title={translate('resources.users.name', { smart_count: 2, _: "Users" })}
                            value={stats?.users}
                            icon={<UserIcon />}
                        />
                    </Link>
                </Grid>
            </Grid>

            {/* TODO: add all warnings and points that require attention? */}
            {permissions?.includes('is_admin') && (
                <Grid container spacing={2} sx={{ marginTop: '15px' }}>
                    <Grid item lg={3} sm={6} xl={3} xs={12}>
                        <Link to='/leaves?filter={"status": "pending"}'>
                            <IconCard
                                title={translate('Pending leaves')}
                                value={stats?.leaves?.pending}
                                icon={<EditCalendarIcon />}
                                warning={stats?.leaves?.pending > 0}
                            />
                        </Link>
                    </Grid>
                </Grid>
            )}
        </>
    );
}

export default Dashboard;