import * as React from 'react';
import { DashboardMenuItem, Menu as RAMenu, MenuItemLink, useGetResourceLabel } from 'react-admin';
import Divider from '@mui/material/Divider';
import InfoIcon from '@mui/icons-material/Info';

import users from './users';
import customers from './customers';
import projects from './projects';
import leaves from './leaves';


const Menu = ({ onMenuClick, ...props }) => {
	const getResourceLabel = useGetResourceLabel();

	return (
		<RAMenu {...props}>
			<DashboardMenuItem />
			<MenuItemLink
				to={`/customers`}
				primaryText={getResourceLabel('customers', 2)}
				leftIcon={<customers.icon />}
				onClick={onMenuClick}
			/>
			<MenuItemLink
				to={`/projects`}
				primaryText={getResourceLabel('projects', 2)}
				leftIcon={<projects.icon />}
				onClick={onMenuClick}
			/>
			<MenuItemLink
				to={`/leaves`}
				primaryText={getResourceLabel('leaves', 2)}
				leftIcon={<leaves.icon />}
				onClick={onMenuClick}
			/>
			<MenuItemLink
				to={`/users`}
				primaryText={getResourceLabel('users', 2)}
				leftIcon={<users.icon />}
				onClick={onMenuClick}
			/>
			<Divider />
			<MenuItemLink
				to={`/useful-links`}
				primaryText="Useful links"
				leftIcon={<InfoIcon />}
				onClick={onMenuClick}
			/>
		</RAMenu>
	);
};

export default Menu;
