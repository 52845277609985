import React from 'react';
import { Datagrid, TextField, ReferenceField } from 'react-admin';

import DateField from '../common/fields/DateField';
import RecordSplitButton from '../common/buttons/RecordSplitButton';
import StatusField from './fields/StatusField';
import TypeField from './fields/TypeField';
import DurationField from './fields/DurationField';

const SplitButton = (props) => (
    <RecordSplitButton {...props} hasEdit={props?.record?.status?.status === 'pending'} />
);

const LeaveRow = ({ children, ...props }) => (
    <Datagrid {...props}>
        <TextField source="id" />
        <ReferenceField label="User" source="user_id" reference="users" allowEmpty link="show">
            <TextField source="fullname" />
        </ReferenceField>
        <TypeField source="type" />
        <DateField source="start_date" />
        <DateField source="end_date" />
        <DurationField />
        <StatusField />
        <DateField source="updated_at" showTime />
        {children}
        <SplitButton />
    </Datagrid>
);

export default LeaveRow;