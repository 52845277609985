const translations = {
    resources: {
        invoices: {
            name: 'Facture |||| Factures'
        },
        leaves: {
            name: 'Congé |||| Congés',
            fields: {
                start_date: 'Date de début',
                end_date: 'Date de fin',
                duration: 'Durée',
                status: 'Statut',
                comments: 'Commentaires',
                user_id: 'Utilisateur'
            }
        },
        customers: {
            name: 'Client |||| Clients',
            fields: {
                name: 'Nom',
                short_name: 'Nom court'
            }
        },
        projects: {
            name: 'Projet |||| Projets',
            fields: {
                name: 'Nom'
            }
        },
        users: {
            name: 'Utilisateur |||| Utilisateurs',
            fields: {
                active: 'Actif',
                email: 'E-mail',
                firstname: 'Prénom',
                lastname: 'Nom',
                password: 'Mot de passe'
            }
        },
        account: {
            fields: {
                firstname: 'Prénom',
                lastname: 'Nom',
                password: 'Mot de passe'
            }
        }
    },
    'User': 'Utilisateur',
    'Name': 'Nom',
    'Number of days': 'Nombre de jours',
    'Search': 'Recherche',
    'Account': 'Compte',
    'Account updated': 'Compte mis à jour',
    'Leave validation': 'Validation du congé',
    'Other leaves at the same period': 'Autres congés à la même période',
    'Pending': 'En attente',
    'Accepted': 'Accepté',
    'Refused': 'Refusé',
    'Leave': 'Congé',
    'Recup': 'Récup',
    'Other': 'Autre',
    'Useful links': 'Liens utiles',
    'Leaves': 'Congés',
    'Must be from the same month': 'Doit être du même mois',
    'Pending leaves': 'Congés en attente',
    'Current month': 'Mois courant',
    'This month': 'Ce mois-ci',
    'Next month': 'Mois prochain',
    'Last month': 'Mois précédent',
    'This year': 'Cette année',
    'Last year': 'L\'année dernière',
    'Reset': 'Réinitialiser',
    'Total leave taken': 'Total congés pris',
    'Total remaining leave': 'Total congés restants',
    'Recuperation days': 'Jours de récupération',
    'Other leave days': 'Autres jours de congés',
    'Customer': 'Client',
    'Users': 'Utilisateurs'
};

export default translations;
