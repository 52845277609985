import { API_URL } from './settings';

const getOptions = () => ({
	headers: new Headers({
		'Content-Type': 'application/json',
		'Authorization': `Bearer ${localStorage.getItem('token')}`
	})
});

export const search = async (terms) => {
	const response = await fetch(`${API_URL}/search/live?q=${terms}`);
	const { results } =  await response.json();
	return results;
};

export const fetchAdminDashboardStats = async () => {
	const response = await fetch(`${API_URL}/admin/dashboard/stats`);
	return await response.json();
};

/* Account */
export const getAccount = async () => {
	const response = await fetch(`${API_URL}/account`, getOptions());
	return await response.json();
};

export const updateAccount = async (data) => {
	const response = await fetch(`${API_URL}/account`, { ...getOptions(), method: 'PUT', body: data });
	return await response.json();
};

export const forgotPassword = async (data) => {
	const response = await fetch(`${API_URL}/account/forget_password`, { method: 'POST', body: data });
	return await response.json();
};

export const resetPassword = async (data) => {
	const response = await fetch(`${API_URL}/account/reset_password`, { method: 'POST', body: data });
	return await response.json();
};

/* Leave */
export const markAcceptedLeave = async (id, data) => {
	const response = await fetch(`${API_URL}/leaves/${id}/mark_accepted`, { ...getOptions(), method: 'POST', body: data });
	return await response.json();
};

export const markRefusedLeave = async (id, data) => {
	const response = await fetch(`${API_URL}/leaves/${id}/mark_refused`, { ...getOptions(), method: 'POST', body: data });
	return await response.json();
};
