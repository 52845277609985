import React, { useState, useEffect } from 'react';
import { SimpleShowLayout, TextField, ReferenceField, TextInput, usePermissions, SimpleForm, Pagination, useRecordContext, useTranslate, useNotify, useRefresh } from 'react-admin';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import { useWatch } from 'react-hook-form';
import moment from 'moment';

import { ShowBase, ShowActions } from '../common/views/Show';
import DateField from '../common/fields/DateField';
import MultilineTextField from '../common/fields/MultilineTextField';
import CustomList from '../common/views/CustomList';
import TypeField from './fields/TypeField';
import DurationField from './fields/DurationField';
import StatusField from './fields/StatusField';
import LeaveRow from './LeaveRow';

import { markAcceptedLeave, markRefusedLeave, getAccount } from '../../services/api';

const LeaveShowActions = (props) => (
    <ShowActions {...props} hasEdit={props?.data?.status === 'pending'} />
);

const ButtonsForm = () => {
    const record = useRecordContext();
    const comments = useWatch({ name: 'comments' });
    const translate = useTranslate();
    const notify = useNotify();
    const refresh = useRefresh();

    const handleAccept = async () => {
        const formData = new FormData();
        formData.append('comments', comments);
        const response = await markAcceptedLeave(record.id, JSON.stringify({ comments }));

        if (response.success) {
            notify(`Leave accepted`);
            refresh();
        } else if (response.reason) {
            notify(response.reason, 'warning');
        }
    };

    const handleRefuse = async () => {
        const response = await markRefusedLeave(record.id, JSON.stringify({ comments }));

        if (response.success) {
            notify(`Leave refused`);
            refresh();
        } else if (response.reason) {
            notify(response.reason, 'warning');
        }
    };

    return (
        <Grid container spacing={2}>
            <Grid item>
                <Button variant="contained" color="success" onClick={handleAccept}>{translate('Accept')}</Button>
            </Grid>
            <Grid item>
                <Button variant="contained" color="error" onClick={handleRefuse}>{translate('Refuse')}</Button>
            </Grid>
        </Grid>
    );
};

const LeaveForm = () => {
    const record = useRecordContext();

    return (
        <SimpleForm defaultValues={{ comments: record?.comments }} toolbar={false}>
            <TextInput source="comments" options={{ multiline: true }} multiline fullWidth rows="4" />
            <ButtonsForm />
        </SimpleForm>
    );
};

const CommentsField = () => {
    const record = useRecordContext();
    const { permissions } = usePermissions();
    const [account, setAccount] = useState({});

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await getAccount();
                setAccount(response);
            } catch (error) {
                console.error(error);
            }
        };

        fetchData();
    }, []);

    if (!permissions?.includes('is_admin') && record.user_id !== account.id) {
        return null;
    }

    return (
        <MultilineTextField source="comments" />
    );
};

const OtherLeaveList = () => {
    const record = useRecordContext();
    const translate = useTranslate();

    if (!record?.start_date || !record?.end_date) {
        return null;
    }

    const startDate = moment(record.start_date).subtract(7, 'days').format('YYYY-MM-DD');
    const endDate = moment(record.end_date).add(7, 'days').format('YYYY-MM-DD');

    return (
        <Grid item xs={12}>
            <Card>
                <CardHeader title={translate('Other leaves at the same period')} />
                <CardContent>
                    <CustomList
                        resource="leaves"
                        sort={{ field: 'start_date', order: 'ASC' }}
                        filter={{ start_date: startDate, end_date: endDate , status: 'accepted' }}
                        basePath="/leaves"
                        filters={null}
                        bulkActionButtons={false}
                        exporter={false}
                        actions={false}
                        pagination={<Pagination rowsPerPageOptions={[10]} />}
                        perPage={10}
                        sx={{ '& .RaList-content': { boxShadow: 'none !important' }}}
                    >
                        <LeaveRow />
                    </CustomList>
                </CardContent>
            </Card>
        </Grid>
    );
};

const CustomDivider = () => <Divider width="100%" />;

const LeaveShow = () => {
    const { permissions } = usePermissions();
    const translate = useTranslate();

    return (
        <ShowBase>
            <LeaveShowActions />
            <Grid container spacing={2} sx={{ marginBottom: '30px' }}>
                <Grid item xs={12}>
                    <Paper>
                        <SimpleShowLayout>
                            <ReferenceField label="User" source="user_id" reference="users">
                                <TextField source="fullname" />
                            </ReferenceField>
                            <TypeField />
                            <DateField source="start_date" />
                            <DateField source="end_date" />
                            <DurationField label="Number of days" />
                            <MultilineTextField source="description" />

                            <CustomDivider label={false} />

                            <StatusField source="status" />
                            <CommentsField source="comments" />
                        </SimpleShowLayout>
                    </Paper>
                </Grid>
                {permissions?.includes('is_admin') && (
                    <Grid item xs={12}>
                        <Card>
                            <CardHeader title={translate('Leave validation')} />
                            <CardContent>
                                <LeaveForm />
                            </CardContent>
                        </Card>
                    </Grid>
                )}
                {permissions?.includes('is_admin') && (
                    <OtherLeaveList />
                )}
            </Grid>
        </ShowBase >
    );
};

export default LeaveShow;