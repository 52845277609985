import React, { useEffect, useState } from 'react';
import { ReferenceInput, AutocompleteInput, DateInput, ListBase, Title, Pagination, ListToolbar, TopToolbar, usePermissions, useGetList, useGetResourceLabel, useTranslate } from 'react-admin';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import EventBusyIcon from '@mui/icons-material/EventBusy';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { useFormContext } from 'react-hook-form';
import moment from 'moment';

import { DefaultListActions } from '../common/views/List';
import IconCard from '../common/IconCard';
import SplitButton from '../common/buttons/SplitButton';
import StatusInput from './inputs/StatusInput';
import TypeInput from './inputs/TypeInput';
import LeaveRow from './LeaveRow';

import { getAccount } from '../../services/api';

const OFFICIAL_DAYS = 20;
const EXTRA_DAYS = 6;

const userOptionText = choice => choice?.fullname ? choice.fullname : '';


const DateButtons = () => {
    const { setValue } = useFormContext();
    const translate = useTranslate();

    const handleClickThisMonth = () => {
        setValue('start_date', moment().startOf('month').format('YYYY-MM-DD'));
        setValue('end_date', moment().endOf('month').format('YYYY-MM-DD'));
    };

    const handleClickLastMonth = () => {
        setValue('start_date', moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD'));
        setValue('end_date', moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD'));
    };

    const handleClickNextMonth = () => {
        setValue('start_date', moment().add(1, 'months').startOf('month').format('YYYY-MM-DD'));
        setValue('end_date', moment().add(1, 'months').endOf('month').format('YYYY-MM-DD'));
    };

    const handleClickThisYear = () => {
        setValue('start_date', moment().startOf('year').format('YYYY-MM-DD'));
        setValue('end_date', moment().endOf('year').format('YYYY-MM-DD'));
    };

    const handleClickLastYear = () => {
        setValue('start_date', moment().subtract(1, 'years').startOf('year').format('YYYY-MM-DD'));
        setValue('end_date', moment().subtract(1, 'years').endOf('year').format('YYYY-MM-DD'));
    };

    const handleClickReset = () => {
        setValue('start_date', null);
        setValue('end_date', null);
    };

    return (
        <SplitButton closeOnButtonClick sx={{ height: '40px' }}>
            <Button onClick={handleClickThisMonth}>{translate('This month')}</Button>
            <Button onClick={handleClickNextMonth}>{translate('Next month')}</Button>
            <Button onClick={handleClickLastMonth}>{translate('Last month')}</Button>
            <Button onClick={handleClickThisYear}>{translate('This year')}</Button>
            <Button onClick={handleClickLastYear}>{translate('Last year')}</Button>
            <Button onClick={handleClickReset}>{translate('Reset')}</Button>
        </SplitButton>
    );
};

const leaveFilters = [
    <ReferenceInput label="User" source="user_id" reference="users" sort={{ field: 'name', order: 'ASC' }} alwaysOn>
        <AutocompleteInput optionText={userOptionText} size="small" />
    </ReferenceInput>,
    <TypeInput source="type" alwaysOn />,
    <StatusInput source="status" alwaysOn />,
    <DateInput source="start_date" alwaysOn />,
    <DateInput source="end_date" alwaysOn />,
    <DateButtons source="date" alwaysOn />
];

const AdminLeaveView = () => {
    const { data: users } = useGetList(
        'users/leaves',
        {
            pagination: { page: 1, perPage: 30 },
            sort: { field: 'id', order: 'DESC' }
        }
    );
    const translate = useTranslate();

    return (
        <Grid container spacing={2}>
            {users?.map(user => (
                <Grid item lg={3} sm={6} xl={3} xs={12} key={`user_leave_${user.id}`}>
                    <IconCard title={user.fullname} value={`${user?.taken_leaves || 0}/${OFFICIAL_DAYS + EXTRA_DAYS}`} icon={null} variant="rounded">
                        <Typography sx={{ marginTop: '10px' }}>{translate('Current month')}: {user.current_month_leaves}</Typography>
                    </IconCard>
                </Grid>
            ))}
        </Grid>
    );
};

const PersonalLeaveView = () => {
    const [account, setAccount] = useState();
    const translate = useTranslate();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await getAccount();
                setAccount(response);
            } catch (error) {
                console.error(error);
            }
        };

        fetchData();
    }, []);

    return (
        <Grid container spacing={2}>
            <Grid item lg={3} sm={6} xl={3} xs={12}>
                <IconCard title={translate('Current month')} value={account?.current_month_leaves || 0} icon={<CalendarMonthIcon />} variant="rounded" />
            </Grid>
            <Grid item lg={3} sm={6} xl={3} xs={12}>
                <IconCard title={translate('Total leave taken')} value={`${account?.taken_leaves || 0}/26`} icon={<EventBusyIcon />} variant="rounded" />
            </Grid>
            <Grid item lg={3} sm={6} xl={3} xs={12}>
                <IconCard title={translate('Total remaining leave')} value={`${account?.remaining_leaves || 0}/26`} icon={<EventAvailableIcon />} variant="rounded" />
            </Grid>
            <Grid item lg={3} sm={6} xl={3} xs={12}>
                <Box display="flex" flexDirection="column" justifyContent="center" height="100%">
                    <Typography color="text.secondary">{translate('Recuperation days')}: {account?.taken_recup_leaves || 0}</Typography>
                    <Typography color="text.secondary">{translate('Other leave days')}: {account?.other_taken_leaves || 0}</Typography>
                </Box>
            </Grid>
        </Grid>
    );
};

const CustomList = ({ children, actions, filters, title, ...props }) => {
    const { permissions } = usePermissions();
    const getResourceLabel = useGetResourceLabel();

    return (
        <Box my={3}>
            <ListBase {...props}>
                <Title title={getResourceLabel('leaves', 2)} />
                <Box mb={3}>
                    {permissions?.includes('is_admin') ? (
                        <AdminLeaveView />
                    ) : (
                        <PersonalLeaveView />
                    )}
                </Box>
                <ListToolbar filters={filters} actions={<TopToolbar><DefaultListActions /></TopToolbar>} />
                <Card>
                    {children}
                </Card>
                <Pagination />
            </ListBase>
        </Box>
    );
};

const LeaveList = props => (
    <CustomList {...props} filters={leaveFilters}>
        <LeaveRow />
    </CustomList>
);

export default LeaveList;