import React, { useState } from 'react';
import { List, TopToolbar } from 'react-admin';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import ViewHeadlineIcon from '@mui/icons-material/ViewHeadline';
import ViewModuleIcon from '@mui/icons-material/ViewModule';

import { DefaultListActions } from '../../common/views/List';
import ProjectRow from './ProjectRow';
import ProjectGrid from './ProjectGrid';

const buttonGroupSx = { marginBottom: '-2px' };
const inactiveButtonSx = { color: (theme) => theme.palette.action.disabled };


const ViewButton = ({ viewGrid, setViewGrid }) => {
    const handleClickList = () => {
        setViewGrid(false);
    };

    const handleClickGrid = () => {
        setViewGrid(true);
    };

    return (
        <ButtonGroup size="small" variant="text" sx={buttonGroupSx}>
            <Button onClick={handleClickList} sx={viewGrid ? inactiveButtonSx : {}}><ViewHeadlineIcon /></Button>
            <Button onClick={handleClickGrid} sx={viewGrid ? {} : inactiveButtonSx}><ViewModuleIcon /></Button>
        </ButtonGroup>
    );
};

const ProjectListActions = (props) => (
    <TopToolbar>
        <ViewButton {...props} />
        <DefaultListActions />
    </TopToolbar>
);

const ProjectList = props => {
    const [viewGrid, setViewGrid] = useState(false);

    let customProps = {};
    if (viewGrid) {
        customProps = { component: 'div' };
    }

    return (
        <List {...props} perPage={25} actions={<ProjectListActions viewGrid={viewGrid} setViewGrid={setViewGrid} />} {...customProps}>
            {viewGrid ? (
                <ProjectGrid />
            ) : (
                <ProjectRow />
            )}
        </List>
    );
};

export default ProjectList;