import React from 'react';
import { SimpleForm, TextInput, ReferenceInput, AutocompleteInput, ReferenceArrayInput, AutocompleteArrayInput } from 'react-admin';

import Create from '../common/views/Create';

const optionText = choice => choice.name ? choice.name : '';
const userOptionText = choice => choice ? choice?.fullname : '';

const ProjectCreate = (props) => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="name" />
            <ReferenceInput  source="customer_id" reference="customers" sort={{ field: 'name', order: 'ASC' }}>
                <AutocompleteInput optionText={optionText} size="small" label="Customer" />
            </ReferenceInput>
            <ReferenceArrayInput reference="users" source="user_ids">
                <AutocompleteArrayInput optionText={userOptionText} clearOnBlur={false} label="Users" />
            </ReferenceArrayInput>
            <TextInput source="description" multiline fullWidth rows="4" />
        </SimpleForm>
    </Create>
);

export default ProjectCreate;
