import React from 'react';
import { Title, useTranslate } from 'react-admin';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import MUILink from '@mui/material/Link';
// import Typography from '@mui/material/Typography';


const Link = (props) => (
    <MUILink {...props} target="_blank" rel="noreferrer noopener" />
);

const UsefulLinks = () => {
    const translate = useTranslate();

    return (
        <Box my={3}>
            <Card>
                <Box p={3}>
                    <Title title="Useful links" />
                    {/* <Typography variant="h5">{translate('Management')}</Typography> */}
                    <ul>
                        <li><Link href="https://redmine.kern-it.be">{translate('Redmine')}</Link></li>
                        <li><Link href="https://drive.google.com/drive/folders/19Bc6Pj1ubAwob7HjKDiUKAlX4KSM2Jkc">{translate('Timesheet')}</Link></li>
                        <li><Link href="https://drive.google.com/drive/folders/1wVzHwTjXBhGurqlMvnU6LhVBp6sxhpBf">{translate('Leaves')}</Link></li>
                        <li><Link href="https://drive.google.com/drive/folders/11l-bjfB2UCCBmqWWhY_9zPxEkd--Qk-l">{translate('Braindumps')}</Link></li>
                        <li><Link href="https://docs.google.com/spreadsheets/d/1A1yTvnezNxphJSsC59sYkHAulhq3FrS_klY_hAmurUU/edit?gid=0#gid=0">{translate('KernTalk Schedule')}</Link></li>
                    </ul>

                    {/* TODO: add more links?
                    <Typography>{translate('Rules')}</Typography>
                    <Link href="#">{translate('Valeurs')}</Link>
                    <Link href="#">{translate('Onboarding')}</Link>
                    <Link href="#">{translate('Developer rules')}</Link>
                    <Link href="#">{translate('Designer rules')}</Link>
                    
                    <Typography>{translate('Documentation')}</Typography>
                    <Link href="#">{translate('Knowledge base')}</Link> */}
                </Box>
            </Card>
        </Box>
    );
};

export default UsefulLinks;