import React, { useEffect } from 'react';
import { TextInput, DateInput, NumberInput, ReferenceInput, AutocompleteInput, usePermissions } from 'react-admin';
import { useWatch, useFormContext } from 'react-hook-form';
import moment from 'moment';

import Create from '../common/views/Create';
import SimpleForm from '../common/forms/SimpleForm';
import TypeInput from './inputs/TypeInput';

const userOptionText = choice => choice?.fullname ? choice.fullname : '';

const validateLeave = (values) => {
    const errors = {};

    if (!moment(values.start_date).isSame(values.end_date, 'month')) {
        errors.start_date = 'Must be from the same month';
        errors.end_date = 'Must be from the same month';
    }

    return errors;
};


const DurationInput = () => {
    const { setValue } = useFormContext();
    const startDate = useWatch({ name: 'start_date' });
    const endDate = useWatch({ name: 'end_date' });
    
    const momentStartDate = moment(startDate);
    const momentEndDate = moment(endDate);

    useEffect(() => {
        if (startDate && endDate) {
            const days = momentEndDate.diff(momentStartDate, 'days') + 1;
            if (days > 0) {
                setValue('duration', days);
            }
        }
    }, [startDate, endDate]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <NumberInput source="duration" label="Number of days" step={0.5} min={0.5} />
    );
};

const LeaveCreate = (props) => {
    const { permissions } = usePermissions();

    return (
        <Create {...props}>
            <SimpleForm validate={validateLeave}>
                {permissions?.includes('is_admin') && (
                    <ReferenceInput source="user_id" reference="users" sort={{ field: 'name', order: 'ASC' }}>
                        <AutocompleteInput optionText={userOptionText} size="small" />
                    </ReferenceInput>
                )}
                <TypeInput />
                <DateInput source="start_date" />
                <DateInput source="end_date" />
                <DurationInput />
                <TextInput source="description" multiline fullWidth rows="4" />
            </SimpleForm>
        </Create>
    );
};

export default LeaveCreate;