import React from 'react';
import { Datagrid, TextField, ReferenceField } from 'react-admin';

const ProjectRow = props => (
    <Datagrid {...props}>
        <TextField source="id" />
        <TextField source="name" />
        <ReferenceField label="Customer" source="customer_id" reference="customers" link="show" allowEmpty>
            <TextField source="name" />
        </ReferenceField>
    </Datagrid>
);

export default ProjectRow;