import React from 'react';
import { TextField, BooleanField, EmailField, TextInput, usePermissions } from 'react-admin';
import Typography from '@mui/material/Typography';

import List from '../common/views/List';
import ResourceLinkField from '../common/fields/ResourceLinkField';
import RecordSplitButton from '../common/buttons/RecordSplitButton';
import DateField from '../common/fields/DateField';
import Filter from '../common/forms/Filter';
import Datagrid from '../common/Datagrid';

const FullNameField = ({ record }) => (
	<Typography variant="body2">{record.firstname} {record.lastname}</Typography>
);

const UserFilter = (props) => (
	<Filter {...props}>
		<TextInput label="Search" source="q" alwaysOn />
	</Filter>
);

const UserList = props => {
	const { permissions } = usePermissions();

	return (
		<List filters={<UserFilter />} {...props}>
			<Datagrid>
				<ResourceLinkField label="Id" resource="users">
					<TextField source="id" />
				</ResourceLinkField>
				<ResourceLinkField label="Name" resource="users">
					<FullNameField />
				</ResourceLinkField>
				<EmailField source="email" />
				<DateField source="last_login_at" showTime />
				<BooleanField source="active" />
				{permissions?.includes('is_admin') && <RecordSplitButton {...props} />}
			</Datagrid>
		</List>
	);
};

export default UserList;